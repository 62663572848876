import React, { Component } from "react";

export default class TIFooter extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <p>
            <strong>
              From Idea to Market {"\u2022"} Intro to Robotics Hackathon
            </strong>{" "}
            by{" "}
            <a
              className="texasRedLink underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.femaletechleaders.org/team"
            >
              FTL Hackathons Team
            </a>{" "}
            and{" "}
            <a
              className="texasRedLink underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ti.com/"
            >
              Texas Instruments
            </a>
            .
          </p>
        </div>
      </footer>
    );
  }
}
