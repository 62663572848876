import React, {Component} from "react";
import Map from "../components/Map";

export default class Contact extends Component {

    render() {
        return (
            <div className="content">
                <section>
                    <div className="container">
                        <h3 className="title is-3" style={{color: "black"}}>Contact</h3>
                        <article className="media">
                            <Map />
                            <div className="rows">
                                <div className="row has-text-left">
                                    <p>For questions, drop us an email: <a href={'mailto:hack@femaletechleaders.org'}
                                                                          style={{color: "#ec8c94"}}>hack[at]femaletechleaders.org</a>.
                                    </p>
                                    <p>If you would like to volunteer for FTL, check out our <a href={'https://docs.google.com/document/d/1etdbK6IZIlVPl3G6-Lo2ZcxrflU6NIWrfDmkBvueFss/edit'}
                                        style={{ color: "#ec8c94" }}>open
                                        positions</a>!</p>
                                    <p>To stay informed, follow us on <a
                                        href={'https://www.instagram.com/femaletechleaders/'}
                                        style={{color: "#ec8c94"}}>Instagram</a>,
                                        <a href={'https://www.linkedin.com/company/female-tech-leaders/'}
                                           style={{color: "#ec8c94"}}> LinkedIn</a>,
                                        <a href={'https://twitter.com/FTL_Munich'}
                                           style={{color: "#ec8c94"}}> Twitter</a>, and
                                        <a href={'https://www.facebook.com/FemaleTechLeaders/'}
                                           style={{color: "#ec8c94"}}> Facebook</a>.</p>
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
            </div>
        );
    }

}

