import React, { Component } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faLinkedin, faTwitterSquare} from "@fortawesome/free-brands-svg-icons";

export default class Footer extends Component {
  lastUpdatedDate() {
    let d = new Date()
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${months[d.getMonth()]} ${d.getFullYear()}`
  }
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
            <div className="columns is-centered">
              <a href={`mailto:hack@femaletechleaders.org`}>
                <FontAwesomeIcon icon={faInstagram} size={"2x"}
                                 style={{color: "#ec8c94"}}/> &nbsp;
              </a> &nbsp;
              <a href={`mailto:hack@femaletechleaders.org`}>
                <FontAwesomeIcon icon={faLinkedin} size={"2x"}
                                 style={{color: "#ec8c94"}}/> &nbsp;
              </a>
              <a href={`mailto:hack@femaletechleaders.org`}>
                <FontAwesomeIcon icon={faTwitterSquare} size={"2x"}
                                 style={{color: "#ec8c94"}}/> &nbsp;
              </a> &nbsp;
              <a href={`mailto:hack@femaletechleaders.org`}>
                <FontAwesomeIcon icon={faFacebook} size={"2x"}
                                 style={{color: "#ec8c94"}}/> &nbsp;

              </a>

            </div>

            <strong>
              Last updated: {this.lastUpdatedDate()}
            </strong>{" "}
            by{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://www.femaletechleaders.org/team" style={{color: "#ec8c94"}}>
              FTL Hackathons Team
            </a>
            .
        </div>
      </footer>
    );
  }
}
