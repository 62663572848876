const  config = {
    apiKey: "AIzaSyCgj8W7O0FJjAlgU70lIS_ETlOcFfG4Yn0",
    authDomain: "ftl-hackathons-website-282518.firebaseapp.com",
    databaseURL: "https://ftl-hackathons-website-282518.firebaseio.com",
    projectId: "ftl-hackathons-website-282518",
    storageBucket: "ftl-hackathons-website.appspot.com",
    // messagingSenderId: "751923693000",
    // appId: "1:751923693000:web:148a4b10fbaa0ba4306cfd",
    // measurementId: "G-BKYDB241TP"
};

export default config;