import React, { Component } from "react";

const criteria = [
    { category: 'Usefulness', desc: "how useful is the solution in real life", points: "25" },
    { category: 'Creativity', desc: "out of the box", points: "25" },
    { category: 'Technical Complexity', desc: "complex analysis, computing", points: "20" },
    { category: 'Overall Quality', desc: "progress made during the hackathon", points: "15" },
    { category: 'Presentation', desc: "clear process description/demo", points: "15" }
]

const header = ["Category", "Description", "Points"]

const footer = ["", "", "100"]

export default class JudgingCriteria extends Component {
    renderHeader() {
        return (
            <tr key="header" className="is-selected" style={{"backgroundColor":"#CC0000"}}>
                {header.map((headerItem, i) => {
                    return (
                        <th key={i}>{headerItem}</th>
                    )
                })}
            </tr>
        )
    }

    renderFooter() {
        return (
            <tr key="footer">
                {footer.map((footerItem, i) => {
                    return (
                        <th key={i}>{footerItem}</th>
                    )
                })}
            </tr>
        )
    }

    renderTableData() {
        return (criteria.map(criterion => {
            const { category, desc, points } = criterion //destructuring
            return (
                <tr key={category}>
                    <td>{category}</td>
                    <td>{desc}</td>
                    <td>{points}</td>
                </tr>
            )
        }))
    }

    render() {
        return (
                <div className="container">
                    <div className="content">
                        <table id="criteria" className="table is-striped customBoxShadow" >
                            <thead>
                            {this.renderHeader()}
                            </thead>
                            <tfoot>
                            {this.renderFooter()}
                            </tfoot>
                            <tbody>
                            {this.renderTableData()}
                            </tbody>
                        </table>
                    </div>
                </div>
        );
    }
}
