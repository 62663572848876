import React, { Component } from "react";
import Person from "../../../components/Person";
import pythonImg from "../../../images/Start_Proud/python.png";
import { BrandLink } from "../About";

export default class DataScience extends Component {
    description() {
        return (
          <div className="list--ordered">
            <li>
              Using{" "}
              <BrandLink href={"https://www.python.org/"} linkText="Python" />{" "}
              we will perform statistical analysis
            </li>
          </div>
        );
    }

    render() {
        return (
            <div className="content">
                <Person
                    boldText="Data Science - Understanding Vaccine Hesitancy and its
                    Implications for our Communities"
                    image={pythonImg}
                    smallText="Emily Harason and Patricia Goldberg"
                    bio={this.description()}
                />
            </div>
        );
    }
}
