import React, { Component } from 'react';
import './App.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bulma-social/bin/bulma-social.min.css';

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";

import Home from './pages/Home';
import TexasInstrumentsRobotics from "./pages/TexasInstruments/TIRoboticsHome";
import TIAbout from "./pages/TexasInstruments/TIAbout";
import StartProudHomeComponent from "./pages/StartProud/Home";
import { auth } from './services/firebase';

export const TI_HACKATHON_URL_ENDPOINT = "/texas-instruments-robotics";
export const TI_HACKATHON_ABOUT_URL_ENDPOINT = "/about-texas-instruments-robotics";
export const START_PROUD_URL_ENDPOINT = "/vaccine-hesitancy-start-proud";

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true,
    };
  }

  componentDidMount() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          authenticated: true,
          loading: false,
        });
      } else {
        this.setState({
          authenticated: false,
          loading: false,
        });
      }
    })
  }

  render() {
    return this.state.loading === true ? <h2>Loading...</h2> : (
      <Router>
        <Switch>
          <Route exact path="/" authenticated={this.state.authenticated} component={Home}>
          </Route>
          <Route path={TI_HACKATHON_URL_ENDPOINT} component={TexasInstrumentsRobotics} />
          <Route path={TI_HACKATHON_ABOUT_URL_ENDPOINT} component={TIAbout} />
          <Route path={START_PROUD_URL_ENDPOINT} component={StartProudHomeComponent} />
          <Route path='*' exact component={Home}>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    );
  }
}