import React, { Component } from "react";
import { START_PROUD_WEBSITE, FTL_WEBSITE } from "./Header";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <p>
            <strong>Hacking for Health {"\u2022"} Vaccine Hesitancy</strong> by{" "}
            <a
              className="link-primary underline"
              target="_blank"
              rel="noopener noreferrer"
              href={`${FTL_WEBSITE}/team`}
            >
              FTL Hackathons Team
            </a>{" "}
            and{" "}
            <a
              className="link-primary underline"
              target="_blank"
              rel="noopener noreferrer"
              href={START_PROUD_WEBSITE}
            >
              Start Proud
            </a>
            .
          </p>
        </div>
      </footer>
    );
  }
}
