import React, { Component } from "react";
import ti_RSLK_TEAM from "../../images/TI_Robotics_Hackathon/ti_RSLK_TEAM.jpg";
import { SectionTitle } from "./TIRoboticsHome";
import TIHeader from "./TIHeader";

export default class TIAbout extends Component {
  rollingAdmissionsDeadline() {
    return (
      <>
        <h3 className="title is-4">Deadline & Rolling Admissions</h3>
        <p>
          The last possible day to register for this event is{" "}
          <b>17 October 2020</b>.
        </p>
        <p style={{ marginTop: "1rem", marginBottom: "2rem" }}>
          <b>Please note</b>: Since this is a hardware hackathon, it is vital
          that everyone who has been accepted to this event confirms their
          attendance and blocks October 30 and 31 immediately. Robots will be
          shipped to your home address, thus making any last minute changes very
          difficult. Because of this, the FTL Hackathons Team will be using a
          Rolling Admissions method, meaning if you’re a perfect candidate,
          you’re likely to be accepted to the Hackathon before the official
          deadline of 17 October 2020.
        </p>
      </>
    );
  }
  about() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <img
              src={ti_RSLK_TEAM}
              title="Engineers testing robotic system"
              alt="The RSLK MAX"
              className="teaser-image"
            />
            <div className="content justify-text">
              <blockquote>
                We interact with hundreds of tiny computers every day that are
                embedded into our houses, our cars, our toys, and our work. As
                our world has become more complex, so have the capabilities of
                the microcontrollers embedded into our devices{" "}
                <cite>
                  <a
                    className="texasRedLink"
                    href="http://users.ece.utexas.edu/~valvano/arm/msp432.htm"
                  >
                    (utexas)
                  </a>
                </cite>
                .
              </blockquote>
              <p>
                <b>FTL</b> and <b>Texas Instruments</b> are excited to announce
                our upcoming virtual hackathon, in celebration of embedded
                systems and robotics! This two day event will bring together
                students and recent graduates within the electrical and computer
                engineering fields to discover the fundamentals of
                microcontroller interfacing and real-time programming in the
                context of robotics. Most of the software development will be
                written in C. Though this event is virtual, Texas Instruments
                will ensure that each participant has access to the{" "}
                <a
                  className="texasRedLink"
                  href="https://www.ti.com/tool/TIRSLK-EVM?keyMatch=RSLK%20MAX&tisearch=Search-EN-everything&usecase=part-number"
                >
                  RSLK MAX Lab kit
                </a>
                , which when assembled, is a fully-functioning robot built for
                real-world applications to aid in the acceleration of learning
                robotics.
              </p>
              <p>
                Moreover, Texas Instruments is excited to walk participants
                through the entire hackathon process. This means that not only
                will each team of 3-4 participants have a dedicated coach, the
                hackathon will also feature a workshop on how to go from idea to
                functional prototype, with tips on market research, functional
                prototyping, basic business elements, and how to pitch!
              </p>

              <h3 className="title is-4">Participant Eligibility</h3>
              <ul>
                <li>
                  Electrical and computer engineering students or recent
                  graduates
                </li>
                <li>Knowledge of C programming</li>
                <li>
                  Located in Germany (RSLK MAX Lab kits can only be sent to
                  German addresses, approximately one week prior to October 30,
                  2020)
                </li>
              </ul>
              {this.rollingAdmissionsDeadline()}
              {this.register()}
              <h3 className="title is-4">Teams Breakdown</h3>
              <ul>
                <li>Event: 20 participants</li>
                <li>8 teams of 3-4</li>
                <li>8 coaches</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
  challenges() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <div className="content justify-text">
              For this hackathon, participants must choose one of the following
              challenges to work on. These challenges will determine the teams
              that participants are sorted into. While each participant will
              have access to their own{" "}
              <a
                className="texasRedLink"
                href="https://training.ti.com/ti-robotics-system-learning-kit-max"
                target="_blank"
                rel="noopener noreferrer"
              >
                RSLK MAX robot
              </a>
              , teams will work together by sharing ideas, code, and presenting
              one business pitch about their final product to the panel of
              judges. Each team will have a designated coach assigned to them
              from TI.
              <div className="paddingLeft">
                <ol type="1">
                  <li className="title is-5">
                    Automotive
                    <ul className="subtitle is-6">
                      <li>autonomous driving, parking, etc.</li>
                    </ul>
                  </li>
                  <li className="title is-5">
                    Personal electronics (household)
                    <ul className="subtitle is-6">
                      <li>vacuum robot, etc.</li>
                    </ul>
                  </li>
                  <li className="title is-5">
                    Industrial
                    <ul className="subtitle is-6">
                      <li>transportation, etc.</li>
                    </ul>
                  </li>
                  {/* <li className="title is-5">
                        Open
                        <ul className="subtitle is-6">
                          <li>
                            Do you have a fabulous idea that will turn your RSLK MAX
                            robot into an award-winning pitch? Submit your idea
                            here, or vote for existing ideas!
                          </li>
                        </ul>
                      </li> */}
                </ol>
              </div>
              {/* <div className="iFrameWrapper">
                    <iframe
                      title="Challenge Idea Submission"
                      width="100%"
                      height="400"
                      src="https://www.tricider.com/embed/?show=3DP1YqnX1XR"
                    ></iframe>
                  </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  register() {
    return (
      <button
        className="button registerNowButton is-light"
        target="_blank"
        rel="noopener noreferrer"
        href="https://forms.gle/VzZmUBfP436mSbk28"
        disabled
      >
        Register Now
      </button>
    );
  }
  title() {
    return (
      <h1 className="title is-1">
        From Idea to Market: Intro to Robotics Hackathon
      </h1>
    );
  }
  render() {
    return (
      <section className="Home">
        <div className="container welcome-container">
        <TIHeader />
        {this.title()}
        <div id={"about"}>{this.about()}</div>
        <div id={"challenges"}>
          <SectionTitle section={"Challenges"} />
          {this.challenges()}
        </div>
      </div>
      </section>
    );
  }
}
