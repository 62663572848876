import React, {Component} from "react";
import Person from "./Person";
import aeishImg from "../images/FTL_Team/aeish.jpeg";
import ashmiImg from "../images/FTL_Team/ashmi.jpg";
import emilyImg from "../images/FTL_Team/emily.jpg";
import kristinaImg from "../images/FTL_Team/kristina.jpg";
import patriciaImg from "../images/FTL_Team/patricia.jpg";
import yoanaImg from "../images/FTL_Team/yoana.jpg";

const FTL_LIGHT_PINK = "#ec8c94";

export default class Team extends Component {
    render() {
        return (
            <div className="content">
                <section>
                    <div className="container">
                        <h3 className="title is-3">Team</h3>
                    </div>
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <Person
                                    boldText="Ashmi"
                                    image={ashmiImg}
                                    smallText="Software Developer"
                                    email={"ashmi@femaletechleaders.org"}
                                    linkedIn={"https://www.linkedin.com/in/ashmi-banerjee/"}
                                    iconColor={FTL_LIGHT_PINK}
                                />
                            </div>
                            <div className="column">
                                <Person
                                    boldText="Aeishwarya"
                                    image={aeishImg}
                                    smallText="Electrical Engineer"
                                    email={"aeish@femaletechleaders.org"}
                                    linkedIn={"https://www.linkedin.com/in/aeishb/"}
                                    iconColor={FTL_LIGHT_PINK}
                                />
                            </div>
                            <div className="column">
                                <Person
                                    boldText="Emily"
                                    image={emilyImg}
                                    smallText="Software Developer"
                                    email={"emily@femaletechleaders.org"}
                                    linkedIn={"https://www.linkedin.com/in/emilyharason/"}
                                    iconColor={FTL_LIGHT_PINK}
                                />
                            </div>

                        </div>
                        <div className="columns">
                            <div className="column">
                                <Person
                                    boldText="Kristina"
                                    image={kristinaImg}
                                    smallText="Quality Assurance Engineer"
                                    email={"kristina@femaletechleaders.org"}
                                    linkedIn={"https://www.linkedin.com/in/kristina-skoryk-0bb147123/"}
                                    iconColor={FTL_LIGHT_PINK}
                                />
                            </div>
                            <div className="column">
                                <Person
                                    boldText="Patricia"
                                    image={patriciaImg}
                                    smallText="Data Scientist"
                                    email={"patricia@femaletechleaders.org"}
                                    linkedIn={"https://www.linkedin.com/in/patricia-goldberg/"}
                                    iconColor={FTL_LIGHT_PINK}
                                />
                            </div>
                            <div className="column">
                                <Person
                                    boldText="Yoana"
                                    image={yoanaImg}
                                    smallText="Software Developer"
                                    email={"yoana@femaletechleaders.org"}
                                    linkedIn={"https://www.linkedin.com/in/yoana-dimova-04b13482/"}
                                    iconColor={FTL_LIGHT_PINK}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
