import React, {Component} from "react";
import Footer from "./Footer";
import Header from "./Header";
import banner from "../../images/Start_Proud/FTL_STARTPROUD_banner_sm.png";
import JudgingCriteria from "./JudgingCriteria";
import DesignThinking from "./Workshops/DesignThinking";
import DataScience from "./Workshops/DataScience";
import About from "./About";
import { BrandLink } from "./About";
import Jury from "./Jury";

export const REGISTRATION_FORM = "https://docs.google.com/forms/d/e/1FAIpQLSdUorf3ePccCvrj2GzrLIopm_ybnzjdFFJCv7xKgPoUR77CHg/viewform";

export class SectionTitle extends Component {
  render() {
    return <div className="title is-4 sectionH1">{this.props.section}</div>;
  }
}

export default class StartProudHomeComponent extends Component {
    title() {
        return (
            <div>
        <span>
          <img
            src={banner}
            alt="Hackathon Banner"
            className="image--banner"
          />
        </span>
      </div>
    );
  }
  timeline() {
    return (
      <div className="list--unordered">
        {/* Register */}
        <b>Beginning May 1st, 2021</b>
        <ul>
          <li>
            <BrandLink href={REGISTRATION_FORM} linkText="Register" />
          </li>
          <ul>
            <li>Register individually or in a team of 3-5 before May 22</li>
            <li>Wait for us to get back to you!</li>
            <li>*** If you haven't heard from us, please check your spam folder!! ***</li>
          </ul>
        </ul>
        {/* After acceptance */}
        <b>May 22nd, 2021</b>
        <ul>
          <li>You should have received an email from us!</li>
          <ul>
            <li>
              We will send you an email with an invite to the hackathon Discord
              channel
            </li>
            <li>Here, you can start meeting fellow hackers and mentors</li>
            <li>Start getting into teams</li>
            <li>Download/install necessary programs</li>
            <li>Ask questions</li>
          </ul>
        </ul>
        {/* Hack Day! */}
        <b>May 29th, 2021</b>
        <ul>
          <li>Hack day!</li>
          <ul>
            <li>Kickoff: 9am Eastern Time | 3pm Central European Time</li>
            <li>Workshops: 9:30am ET | 3:30pm CET</li>
            <li>Presentations: 3pm ET | 9pm CET</li>
            <li>Closing: 4pm ET | 10pm CET</li>
          </ul>
        </ul>
      </div>
    );
  }
  prizes() {
    return (
      <div className="list--ordered">
        <ol type="1">
          <li>First place and Runners up</li>
          <ul>
            <li>$200 worth of prizes</li>
          </ul>

          <li>Everyone</li>
          <ul>
            <li>Certificate</li>
            <li>Social Media Shout-outs</li>
          </ul>
        </ol>
      </div>
    );
  }
  resources() {
    return (
      <div className="list--unordered">
        <ul>
          <li>
            <BrandLink
              href="https://www.canada.ca/en/public-health/services/reports-publications/canada-communicable-disease-report-ccdr/monthly-issue/2020-46/issue-2-3-february-6-2020/article-5-best-practices-addressing-vaccine-hesitancy.html"
              linkText="Best practices for addressing vaccine hesitancy, CCDR"
            />
          </li>
          <li>
            <BrandLink
              href="https://www.thelancet.com/pdfs/journals/lancet/PIIS0140-6736(20)31558-0.pdf"
              linkText="Mapping global trends in vaccine confidence and
              investigating barriers to vaccine uptake: a large-scale
              retrospective temporal modelling study"
            />
          </li>
          <li>
            <BrandLink
              href="https://www.recover-europe.eu/covid-19-vaccine-hesitancy-is-striking-shows-recover-social-science-study/"
              linkText="COVID-19 vaccine hesitancy is striking, shows RECOVER Social Science study, Recover Europe"
            />
          </li>
          <li>
            <BrandLink
              href="https://www.who.int/news-room/spotlight/ten-threats-to-global-health-in-2019"
              linkText="Ten threats to global health in 2019, WHO"
            />
          </li>
        </ul>
      </div>
    );
  }

  render() {
    return (
      <section className="Home">
        <div className="container welcome-container">
          <Header />
          {this.title()}
          <div id={"about"}>
            <SectionTitle section={"About"} />
            <About />
          </div>
          <div id={"agenda"}>
            <SectionTitle section={"Timeline"} />
            {this.timeline()}
          </div>
          <div id={"workshops"}>
            <SectionTitle section={"Workshops"} />
            <DataScience />
            <DesignThinking />
          </div>
          <div id={"judging-criteria"}>
            <SectionTitle section={"Judging Criteria"} />
            <JudgingCriteria />

            <SectionTitle section={"Jury"} />
            <Jury />
          </div>
          <div id={"prizes"}>
            <SectionTitle section={"Prizes"} />
            {this.prizes()}
          </div>
          <div id={"resources"}>
            <SectionTitle section={"Resources"} />
            {this.resources()}
          </div>
        </div>
        <Footer />
      </section>
    );
  }
}
