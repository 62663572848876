import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import logo from "../../images/ftllogo.png";
import startProudLogo from "../../images/Start_Proud/sp_bilingual.png";
import { START_PROUD_URL_ENDPOINT } from "../../App";

export const START_PROUD_WEBSITE = "https://www.startproud.org/";
export const FTL_WEBSITE = "https//www.femaletechleaders.org";

export default class TIHeader extends Component {
  constructor(props) {
    super(props);

    this.handleTIBurgerClick = this.handleTIBurgerClick.bind(this);
  }

  handleTIBurgerClick(event) {
    event.target.classList.toggle("is-active");
    document.querySelector(".navbar-menu").classList.toggle("is-active");
  }

  render() {
    return (
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand navbar--flex-wrap">
          <a className="navbar-item paddingLeft" href="/">
            <img src={logo} alt="FTL logo" />
          </a>
          <a
            className="navbar-item"
            style={{ maxWidth: 250 }}
            href={START_PROUD_WEBSITE}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={startProudLogo} alt="Start Proud logo" />
          </a>

          <a
            role="button"
            className="navbar-burger burger"
            onClick={this.handleTIBurgerClick}
            href={`${START_PROUD_URL_ENDPOINT}/#about`} // add this for accessibility!
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarMenu"
          >
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
          </a>
        </div>

        <div
          id="navbar-menu"
          onClick={this.handleTIBurgerClick}
          className="navbar-menu"
        >
          <div className="navbar-start">
            <HashLink
              className="navbar-item link-hover--red"
              to={`${START_PROUD_URL_ENDPOINT}/#about`}
              smooth="true"
            >
              About
            </HashLink>
            <HashLink
                className="navbar-item link-hover--red"
                to={`${START_PROUD_URL_ENDPOINT}/#challenges`}
                smooth="true"
            >
              Challenges
            </HashLink>
            <HashLink
              className="navbar-item link-hover--orange"
              to={`${START_PROUD_URL_ENDPOINT}/#agenda`}
              smooth="true"
            >
              Agenda
            </HashLink>
            <HashLink
              className="navbar-item link-hover--yellow"
              to={`${START_PROUD_URL_ENDPOINT}/#workshops`}
              smooth="true"
            >
              Workshops
            </HashLink>
            <HashLink
              className="navbar-item link-hover--green"
              to={`${START_PROUD_URL_ENDPOINT}/#judging-criteria`}
              smooth="true"
            >
              Judging
            </HashLink>
            <HashLink
              className="navbar-item link-hover--purple"
              to={`${START_PROUD_URL_ENDPOINT}/#prizes`}
              smooth="true"
            >
              Prizes
            </HashLink>
            <HashLink
              className="navbar-item link-hover--pink"
              to={`${START_PROUD_URL_ENDPOINT}/#resources`}
              smooth="true"
            >
              Resources
            </HashLink>
          </div>
          <div className="navbar-end"></div>
        </div>
      </nav>
    );
  }
}
