import React, { Component } from "react";
import Person from "../../components/Person";
import patriciaImg from "../../images/FTL_Team/patricia.jpg";
import christiaanImg from "../../images/TI_Robotics_Hackathon/headshots/Christiaan.jpg";
import selinaImg from "../../images/TI_Robotics_Hackathon/headshots/Selina.jpg";

const TI_RED = "CC0000";

export default class TIJury extends Component {
    patriciaBio() {
        return (
            <div className="justify-text">
                <p>Patricia Goldberg is a Data Scientist located in Munich. She is passionate about data and technology and
                advocates for more women in tech through her skills. She has been an active member of women in technology groups since 2017
                (including Women in CS @ TUM and Female Tech Leaders). Through this time, she has participated in several
                worldwide conferences, talks, and discussions on the subject. Moreover, she has participated in research on
                Hidden Bias at the Technical University of Munich, in which she and her advisor recently published a
                    paper at the ECSA 2020, on the gender diversity track.</p>

                <p>Patricia holds a bachelor's degree in Information Systems from the University of Sao Paulo, Brazil. During
                her bachelor studies, she was an exchange student for a year at the University of Bristol, including three months
                of research in Machine Learning. Patricia recently completed her master's degree in Data Engineering and
                Analytics from the Technical University of Munich, Germany, and currently works as a Data Scientist at Agrando.
                </p>
            </div>
        )
    }

    christiaanBio() {
        return (
            <div className="justify-text">
                <p>Christiaan Laseur is an EMEA wide Recruiting Manager and leads a team responsible for all recruiting activities
                in Europe. The focus of the team is to attract engineering students and young professionals for suitable roles
                within Texas Instruments. Before TI, Christiaan worked as a recruiting manager and headhunter for Healthcare
                    and Medtech companies. </p>

                <p>Christiaan holds a bachelor’s degree in Business Administration from the University in Ede (Netherlands)
                Please feel free to contact me on LinkedIn.
                </p>
            </div>
        )
    }

    selinaBio() {
        return (
            <div className="justify-text">
                <p>Selina covers Artificial Intelligence, Blockchain, Start-up and Augmented and Virtual Reality topics at
                Elektronik Neo. </p>
                <p>She has a degree in Technical Journalism from Bonn-Rhein-Sieg University of Applied
                Sciences, has an affinity for video and travels the world. In her spare time she loves Yoga and to
meditate. One of her passions is always challenging herself to try out new things.</p>
            </div>
        )
    }

    render() {
        return (
            <div className="content">
                <Person
                    boldText="Christiaan Laseur"
                    image={christiaanImg}
                    smallText="Texas Instruments Recruiting Manager"
                    bio={this.christiaanBio()}
                    linkedIn={"https://de.linkedin.com/in/christiaan-laseur-1a93864"}
                    iconColor={TI_RED}
                />
                <Person
                    boldText="Patricia Goldberg"
                    image={patriciaImg}
                    smallText="Female Tech Leaders Hackathons Team and Data Scientist"
                    bio={this.patriciaBio()}
                    linkedIn={"https://www.linkedin.com/in/patricia-goldberg/"}
                    iconColor={TI_RED}
                />
                <Person
                    boldText="Selina Doulah"
                    image={selinaImg}
                    smallText="Elektronik Neo"
                    bio={this.selinaBio()}
                    xing={"https://www.xing.com/profile/Selina_Doulah"}
                    iconColor={TI_RED}
                />
            </div>
        );
    }
}
