import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../images/ftllogo.png";
import { START_PROUD_URL_ENDPOINT, TI_HACKATHON_URL_ENDPOINT } from "../App";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.handleBurgerClick = this.handleBurgerClick.bind(this);
  }

  handleBurgerClick(event) {
    event.target.classList.toggle('is-active');
    document.querySelector('.navbar-menu').classList.toggle('is-active');
  }

  render() {
    return (
      <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src={logo} alt="FTL logo" height="50" />
          </a>

          <a role="button"
            className="navbar-burger burger"
            onClick={this.handleBurgerClick}
            href={"/#initiative"} // add this for accessibility!
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbar-menu" onClick={this.handleBurgerClick} className="navbar-menu">
          <div className="navbar-start">
            <HashLink className="navbar-item" to="/#initiative" smooth="true">
              Initiative
            </HashLink>
            {/* Dropdown */}
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">
                <HashLink className="navbar-item" to="/#eventsTimeline" smooth="true">
                  Events
                </HashLink>
              </div>
              <div className="navbar-dropdown">
              <Link className="navbar-item" to={START_PROUD_URL_ENDPOINT}>
                  Vaccine Hesitancy Hackathon: May 2021
                </Link>
                <Link className="navbar-item" to={TI_HACKATHON_URL_ENDPOINT}>
                  From Idea to Market: Intro to Robotics Hackathon
                </Link>
              </div>
            </div>
            <HashLink className="navbar-item" to="/#team" smooth="true">
              Team
            </HashLink>
            <HashLink className="navbar-item" to="/#contact" smooth="true">
              Contact
             </HashLink>
          </div>
          <div className="navbar-end"></div>
        </div>
      </nav>
    );
  }
}
