import React, { Component } from "react";
import { SectionTitle, REGISTRATION_FORM } from "./Home";
import { START_PROUD_WEBSITE, FTL_WEBSITE } from "./Header";
import ProblemStatement from "./ProblemStatement";

export class BrandLink extends Component {
  render() {
    return (
      <a
        className="link-primary underline"
        target="_blank"
        rel="noopener noreferrer"
        href={this.props.href}
      >
        {this.props.linkText}
      </a>
    );
  }
}

export default class About extends Component {
  rollingAdmissionsDeadline() {
    return (
      <>
        <h3 className="title is-4" style={{ marginTop: "1em" }}>
          Deadline & Rolling Admissions
        </h3>
        <p>
          The last possible day to register for this event is{" "}
          <b>Saturday May 22, 2021</b>.
        </p>
        <p style={{ marginTop: "1rem", marginBottom: "2rem" }}>
          <b>Please note</b>: The organizing team will be using a Rolling
          Admissions method, meaning you may be accepted before the deadline of
          May 22, 2021.
        </p>
        <p>
        *** If you haven't heard from us, please check your spam folder!! ***
        </p>
      </>
    );
  }
  about() {
    return (
      <div>
        <p>
          <BrandLink href={FTL_WEBSITE} linkText={"Female Tech Leaders"} /> and{" "}
          <BrandLink href={START_PROUD_WEBSITE} linkText={"Start Proud"} /> are
          excited to invite you to participate in our virtual mini-hackathon.
          Participants can choose from one of our two hands-on challenges, to
          explore tools and methods to combat vaccine hesitancy. This one day
          event will bring together students and individuals alike who are
          interested in gaining hands-on experience in UX/UI Design and Data
          Science. Hackers will discover the fundamentals of using technology in
          combination with research methodologies to engage and educate the
          public and our communities. The workshops held will use Figma and
          Python, and a basic knowledge of these tools will be useful, though
          not necessary.
        </p>

        <p style={{ marginTop: "1em" }}>
          Moreover, we are excited to offer ongoing support to participants
          during the hackathon. Mentors will be available on Discord throughout
          the day, ready to answer any and all of your questions!
        </p>
        <h3 className="title is-4" style={{ marginTop: "1em" }}>
          Participant Eligibility
        </h3>
        <ul style={{ listStyle: "revert", paddingLeft: "2em" }}>
          <li>Knowledge of research methodologies</li>
          <li>Enthusiastic about spending your Saturday hacking with us!</li>
        </ul>
        {this.rollingAdmissionsDeadline()}
        {this.register()}
      </div>
    );
  }
  challenges() {
    return (
      <div style={{ marginTop: "1em" }}>
        For this hackathon, participants must choose one of the following
        challenges to work on. These challenges will determine the teams that
        participants are sorted into. Teams will work together by sharing ideas,
        code/prototypes, and will present one pitch about their final product to
        a panel of judges.
        <div className="paddingLeft" style={{ marginTop: "1em" }}>
          <ol type="1">
            <li className="title is-5">
              Design Thinking: Design a webpage/app that builds trust and
              invokes confidence
            </li>
            <p style={{ margin: "1em" }}>
              Vaccination and Trust do not always go hand in hand, especially in
              today's age of misinformation and information overload. A 2017
              report from the World Health Organization covered "...how building
              trust, listening to and understanding people, building relations,
              communicating risk and shaping messages to the audiences may
              mitigate crises." (euro.who.int). These findings are super
              relevant when writing reports, but how can we ensure users will
              trust our web pages enough to read them, and perform some sort of
              action (e.g. booking an appointment for a vaccination)?
            </p>
            <p style={{ margin: "1em" }}>
              Luckily, with the emergence of User Experience and User Design
              Research, psychology is now at the forefront of design thinking
              for technology. In this challenge, we invite hackers to use an
              online design tool or app, as well as design thinking
              methodologies, to develop a prototype for a trustworthy webpage or
              app, and present their findings.
            </p>
            <p style={{ margin: "1em" }}>
              This workshop will introduce hackers to{" "}
              <BrandLink href={"https://www.figma.com/"} linkText="Figma" /> as
              well as look at a case study for a "trustworthy" website.
            </p>

            <li className="title is-5">
              Data Science: Understanding Vaccine Hesitancy and its Implications
              for our Communities
            </li>
            <p style={{ margin: "1em" }}>
              Qualitative and quantitative research shows that while science
              provides evidence for eradication of disease via vaccines,
              individuals continue to report hesitancy. In this challenge, we
              invite hackers to study data from qualitative surveys, or any
              other data source they wish, and to create an analysis for
              stakeholders on what are the acceptance rates and factors
              influencing the acceptance of a vaccine.
            </p>
            <p style={{ margin: "1em" }}>
              A Workshop demonstrating how to fetch and analyze survey data from
              a <b>.csv</b> file using{" "}
              <BrandLink
                href={"https://www.python.org/"}
                linkText="Python"
              />{" "}
              will aid participants in presenting their findings.
            </p>
          </ol>
        </div>
      </div>
    );
  }
  register() {
    return (
      <div style={{ margin: "1em 0 1em" }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={REGISTRATION_FORM}
          className="button button--register-now is-light"
          disabled
        >
          Register Now
        </a>
      </div>
    );
  }
  render() {
    return (
      <>
        <div>{this.about()}</div>
        <div>
          <SectionTitle section={"Problem Statement"} />
          <ProblemStatement />
        </div>
        <div id={"challenges"}>
          <SectionTitle section={"Challenges"} />
          {this.challenges()}
        </div>
      </>
    );
  }
}
