import React, { Component } from "react";
import Person from "../../components/Person";
import elektronikNeo from "../../images/TI_Robotics_Hackathon/elektronikNeo.jpg";
import powerBank from "../../images/TI_Robotics_Hackathon/powerBankPhoto.jpg";
import wirelessCharger from "../../images/TI_Robotics_Hackathon/wirelessCharger.jpg";
import logo_instagram from "../../images/logo_instagram.png";

export default class TIPrizes extends Component {
  overview() {
    return (
      <div className="content justify-text">
        Each participant will be sent an RSLK MAX Lab kit for the purpose of the
        hackathon. Exciting goodies will be included in your package!!
      </div>
    );
  }

  powerBankDesc() {
    return (
      <div className="content">
        This power bank recharges smartphones and tablets reliably with 16,000
        mAh and a high-performance lithium-ion polymer battery. Thanks to double
        USB output, it can be used simultaneously. Additional features include
        the On/Off-Power-Button and LED charge status.
      </div>
    );
  }

  wirelessChargerDesc() {
    return (
      <div className="content">
        This wireless charger is a 5 watt output and mobile phone holder in one.
        The plastic was partially made from wheat straw fibres, thus reducing
        this charger’s environmental footprint.
      </div>
    );
  }

  elektronikNeoDesc() {
    return (
      <div className="content">
        The top three teams will be featured by our media partner{" "}
        <a
          className="texasRedLink"
          href="https://www.elektronik-neo.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Elektronik neo
        </a>
        ! Elektronik neo is a platform, digital and print magazine for young
        professionals and young potentials in the electronics industry.
      </div>
    );
  }

  participationCert() {
    return (
      <div className="content">
        Every member, from the teams making successful project submissions, will
        receive a participation certificate from Female Tech Leaders.
      </div>
    );
  }

  render() {
    return (
      <div className="content">
        {this.overview()}
        <Person
          boldText="First place"
          image={powerBank}
          smallText="Power Bank"
          bio={this.powerBankDesc()}
        />
        <Person
          boldText="Second place"
          image={wirelessCharger}
          smallText="Wireless phone charger"
          bio={this.wirelessChargerDesc()}
        />
        <Person
          boldText="Top three teams"
          image={elektronikNeo}
          smallText={`Features from Elektronik neo`}
          bio={this.elektronikNeoDesc()}
        />
        <Person
          boldText="Everyone"
          image={logo_instagram}
          smallText={`Participation Certificate from Female Tech Leaders`}
          bio={this.participationCert()}
        />
      </div>
    );
  }
}
