import React, { Component } from "react";
import Person from "../../../components/Person";
import trustImg from "../../../images/Start_Proud/trust_icon.png";
import vivianaImg from "../../../images/Start_Proud/viviana.jpeg";
import zoeImg from "../../../images/Start_Proud/zoe.jpeg";

const SP_BLUE = "#01b0e5";

export default class DesignThinking extends Component {
    description() {
        return (
          <div className="list--ordered">
            <li>Prototyping with Figma</li>
            <li>Introduction to Design Principles</li>
            <li>Case Study: Material Design</li>
            <li>Designing for Trust: Brainstorming</li>
          </div>
        );
    }

    viviana() {
        return (
            <div>
                DSC Munich, Student at TUM, Flutter Freelancer
            </div>
        )
    }

    zoe() {
        return (
            <div>
                Student at University of Applied Scienes Osnabrück, Media & Interaction Design
            </div>
        )
    }

    render() {
        return (
            <div className="content">
                <Person
                    boldText="Designing for Trust - An Explorative Introduction to Digital Prototyping"
                    image={trustImg}
                    smallText="Viviana Sutedjo and Zoe Carolin Then Bergh"
                    bio={this.description()}
                />
                <div className="title is-4 sectionH1">Presenters</div>
                <Person
                    boldText="Viviana Sutedjo"
                    image={vivianaImg}
                    smallText="Workshop Presenter"
                    bio={this.viviana()}
                    linkedIn={"https://www.linkedin.com/in/viviana-sutedjo/"}
                    iconColor={SP_BLUE}
                />
                <Person
                    boldText="Zoe Carolin Then Bergh"
                    image={zoeImg}
                    smallText="Workshop Presenter"
                    bio={this.zoe()}
                    linkedIn={"https://www.linkedin.com/in/zoe-carolin-then-bergh-1511b6203/?originalSubdomain=de"}
                    iconColor={SP_BLUE}
                />
            </div>
        );
    }
}
