import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import logo from "../../images/ftllogo.png";
import ti_logo from "../../images/TI_Robotics_Hackathon/ti_logo.png";
import { TI_HACKATHON_URL_ENDPOINT, TI_HACKATHON_ABOUT_URL_ENDPOINT } from "../../App";
import { Link } from "react-router-dom";

export default class TIHeader extends Component {
  constructor(props) {
    super(props);

    this.handleTIBurgerClick = this.handleTIBurgerClick.bind(this);
  }

  handleTIBurgerClick(event) {
    event.target.classList.toggle('is-active');
    document.querySelector('.navbar-menu').classList.toggle('is-active');
  }

  render() {
    return (
      <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item paddingLeft" href="/">
            <img src={logo} alt="FTL logo" />
          </a>
          <a className="navbar-item" href="https://www.ti.com/" target="_blank" rel="noopener noreferrer">
            <img src={ti_logo} alt="TI logo" />
          </a>

          <a role="button"
            className="navbar-burger burger"
            onClick={this.handleTIBurgerClick}
            href={`${TI_HACKATHON_URL_ENDPOINT}/#about`} // add this for accessibility!
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbar-menu" onClick={this.handleTIBurgerClick} className="navbar-menu">
          <div className="navbar-start">
            <Link className="navbar-item texasInstrumentsHover" to={TI_HACKATHON_ABOUT_URL_ENDPOINT}>
              About
            </Link>
            <HashLink className="navbar-item texasInstrumentsHover" to={`${TI_HACKATHON_URL_ENDPOINT}/#agenda`} smooth="true">
              Agenda
             </HashLink>
            <HashLink className="navbar-item texasInstrumentsHover" to={`${TI_HACKATHON_URL_ENDPOINT}/#speakers`} smooth="true">
              Speakers
            </HashLink>
            <HashLink className="navbar-item texasInstrumentsHover" to={`${TI_HACKATHON_URL_ENDPOINT}/#jury`} smooth="true">
              Jury
            </HashLink>
            <HashLink className="navbar-item texasInstrumentsHover" to={`${TI_HACKATHON_URL_ENDPOINT}/#yoga`} smooth="true">
              Yoga
            </HashLink>
            <HashLink className="navbar-item texasInstrumentsHover" to={`${TI_HACKATHON_URL_ENDPOINT}/#judging-criteria`} smooth="true">
              Judging Criteria
            </HashLink>
            <HashLink className="navbar-item texasInstrumentsHover" to={`${TI_HACKATHON_URL_ENDPOINT}/#prizes`} smooth="true">
              Prizes
             </HashLink>
            <HashLink className="navbar-item texasInstrumentsHover" to={`${TI_HACKATHON_URL_ENDPOINT}/#resources`} smooth="true">
             Resources
            </HashLink>
            <HashLink className="navbar-item texasInstrumentsHover" to={`${TI_HACKATHON_URL_ENDPOINT}/#media_partner`} smooth="true">
             Media Partner
            </HashLink>
          </div>
          <div className="navbar-end"></div>
        </div>
      </nav>
    );
  }
}
