import React from 'react';
import { Link } from "react-router-dom";
import experteer from "../images/FTL_partner_logos/experteer.png";
import tyntec from "../images/FTL_partner_logos/tyntec.jpeg";
import holidu from "../images/FTL_partner_logos/holidu.jpeg";
import google from "../images/FTL_partner_logos/google.png";
import ss from "../images/FTL_partner_logos/socialsw.png";
import ftl from "../images/logo_instagram.png";
import texasInstruments from "../images/FTL_partner_logos/texas_instruments.png";
import startProud from "../images/FTL_partner_logos/start_proud.png";
import { TI_HACKATHON_URL_ENDPOINT, START_PROUD_URL_ENDPOINT } from "../App";
import { START_PROUD_WEBSITE } from '../pages/StartProud/Header';

export default function EventsTimeline() {
    return (
        <div className="content">
            <section style={{ paddingTop: "3rem"}}>
                <div className="container">
                    <h3 className="title is-3" style={{color: "black", margin: 0}}>Events Timeline</h3>

                    <article className="media section">
                        <div className="timeline">
                            {/* 2021 */}
                            <div className="timeline-header">
                                <span className="tag is-medium is-secondary">2021</span>
                            </div>
                            <div className="timeline-item">
                                <div className="timeline-marker is-image is-32x32">
                                    <img src={startProud} style={{ backgroundColor: "white" }} alt="Start Proud logo" height="50"/>
                                </div>
                                <div className="timeline-content">
                                    <span className="heading">Saturday May 29, 2021</span>
                                    <span style={{ backgroundColor: "white" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div>
                                        <Link
                                            className="navbar-item"
                                            to={START_PROUD_URL_ENDPOINT}
                                            style={{ padding: "0 0.25rem" }}
                                        >
                                            <b className="underline">
                                                Vaccine Hesitancy
                                            </b>
                                        </Link>
                                        </div>
                                        <div>
                                        @ <a href={START_PROUD_WEBSITE}>Start Proud | Fier Départ</a>
                                        </div>
                                    </div>
                                    </span>
                                </div>
                            </div>
                            {/* 2020 */}
                            <div className="timeline-header">
                                <span className="tag is-medium is-secondary">2020</span>
                            </div>
                            <div className="timeline-item">
                                <div className="timeline-marker is-image is-32x32">
                                    <img src={texasInstruments} style={{ backgroundColor: "white" }} alt="FTL logo" height="50"/>
                                </div>
                                <div className="timeline-content">
                                    <span className="heading">October 30-31, 2020</span>
                                    <span style={{ backgroundColor: "white" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div>
                                        <Link
                                            className="navbar-item"
                                            to={TI_HACKATHON_URL_ENDPOINT}
                                            style={{ padding: "0 0.25rem" }}
                                        >
                                            <b className="underline">
                                            From Idea to Market: Intro to Robotics Hackathon
                                            </b>
                                        </Link>
                                        </div>
                                        <div>
                                        @ <a href={"https://www.ti.com/"}>Texas Instruments</a>
                                        </div>
                                    </div>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-item">
                                <div className="timeline-marker is-image is-32x32">
                                    <img src={ftl} alt="FTL logo" height="50"/>
                                </div>
                                <div className="timeline-content">
                                    <p className="heading">May 2020</p>
                                    <p><b>Virtual Hackathon:</b> <a
                                        href={'https://bigdatahack.femaletechleaders.org/'}
                                        style={{color: "#ec8c94"}}> Introduction to Big Data - COVID-19 and its Global
                                        Effects </a>@ FTL</p>
                                </div>
                            </div>
                            {/* 2019 */}
                            <header className="timeline-header">
                                <span className="tag is-medium is-secondary">2019</span>
                            </header>
                            <div className="timeline-item">
                                <div className="timeline-marker is-image is-32x32">
                                    <img src={google} alt="Google logo" height="50"/>
                                </div>
                                <div className="timeline-content">
                                    <p className="heading">October 2018</p>
                                    <p><b>Hack in the Google Cloud with FTL</b> @ <a
                                        href={'https://careers.google.com/locations/'}
                                        style={{color: "#ec8c94"}}> Google </a></p>
                                </div>
                            </div>
                            {/* 2018 */}
                            <header className="timeline-header">
                                <span className="tag is-medium is-secondary">2018</span>
                            </header>
                            <div className="timeline-item">
                                <div className="timeline-marker is-image is-32x32">
                                    <img src={ss} alt="Social Sweetheart logo" height="50"/>
                                </div>
                                <div className="timeline-content">
                                    <p className="heading">March 2018</p>
                                    <p><b>Social Infotainment Hackathon</b> @ <a
                                        href={'https://www.linkedin.com/company/social-sweethearts-gmbh/about/'}
                                        style={{color: "#ec8c94"}}> Social Sweethearts </a></p>
                                </div>
                            </div>
                            <div className="timeline-item">
                                <div className="timeline-marker is-image is-32x32">
                                    <img src={holidu} alt="Holidu logo" height="50"/>
                                </div>
                                <div className="timeline-content">
                                    <p className="heading">May 2018</p>
                                    <p><b>Hack your Holidays Hackathon</b> @ <a href={'https://www.holidu.de/'}
                                                                         style={{color: "#ec8c94"}}> Holidu </a></p>
                                </div>
                            </div>
                            <div className="timeline-item">
                                <div className="timeline-marker is-image is-32x32">
                                    <img src={tyntec} alt="Tyntec logo" height="50"/>
                                </div>
                                <div className="timeline-content">
                                    <p className="heading">October 2018</p>
                                    <p><b>Chatbot Challenge</b> @ <a href={'https://www.tyntec.com/'}
                                                              style={{color: "#ec8c94"}}> Tyntec </a></p>
                                </div>
                            </div>
                            {/* 2017 */}
                            <header className="timeline-header">
                                <span className="tag is-medium is-secondary">2017</span>
                            </header>
                            <div className="timeline-item">
                                <div className="timeline-marker is-image is-32x32">
                                    <img src={experteer} alt="Experteer logo" height="50"/>
                                </div>
                                <div className="timeline-content">
                                    <p className="heading">November 2017</p>
                                    <p> <b>Machine Learning Hackathon</b> @ <a href={'https://www.experteer.de/'}
                                                                       style={{color: "#ec8c94"}}> Experteer</a></p>
                                </div>
                            </div>
                        </div>
                        {/*<div className="timeline-header">*/}
                        {/*    <span className="tag is-medium is-primary">2021</span>*/}
                        {/*    */}
                        {/*</div>*/}


                    </article>
                </div>

            </section>
        </div>

    );
}