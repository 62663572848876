import React, { Component } from "react";
import elektronikNeo from "../../images/TI_Robotics_Hackathon/neo_Logo_glow.png";

export default class TIMediaPartner extends Component {
  render() {
    return (
      <>
        <div>
          We are proud to announce our Media Partner for this event,{" "}
          <a className="texasRedLink" href="https://www.elektronik-neo.de/">
            Elektronik neo
          </a>
          . Check out their announcement about this hackathon{" "}
          <a
            className="texasRedLink"
            href="https://www.elektroniknet.de/elektronik/neo/virtueller-robotik-hackathon-frauenpower-ist-gefragt-180001.html"
          >
            here
          </a>
          !
        </div>
        <div className="centerImg padding-top">
          <a
            href="https://www.elektronik-neo.de/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={elektronikNeo} className="decreaseWidth" alt="Elektronik neo logo" />
          </a>
        </div>
      </>
    );
  }
}
