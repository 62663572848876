import React, { Component } from "react";

const diamondSymbol = ` \u25C6 `;
const actionDiamondSymbol = ` \u25C8 `;

export default class TIAgenda extends Component {
  friday() {
    return (
      <div className="content">
        <article className="message is-texasInstruments">
          <div className="message-header">
            <p>Friday October 30, 2020</p>
          </div>
          <div className="message-body">
            <p>
              13:00 - 13:15 {diamondSymbol} <strong>Kickoff</strong>: <a
                href="https://youtu.be/yyNHohj988w"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube LiveStream
              </a>
            </p>
            <p>
              13:15 - 13:25 {actionDiamondSymbol}{" "}
              <strong>Inspirational talk on women in STEM</strong>: Elizabete de
              Freitas, Texas Instruments
            </p>
            <p>
              13:25 - 13:30 {diamondSymbol} <strong>Agenda Overview</strong>:
              Christiaan Laseur, Texas Instruments
            </p>
            <p>
              13:30 - 14:00 {diamondSymbol} <strong>Introduction</strong>:
              Coaches TI, FTL & Participants
            </p>
            <p>
              14:00 - 16:00{actionDiamondSymbol}
              <a
                href="https://university.ti.com/programs/RSLK/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Session RSLK MAX
              </a>
            </p>
            <p>16:15 - 18:00 {diamondSymbol} Coach Time</p>
          </div>
        </article>
      </div>
    );
  }

  halloweenEmoji() {
    return (
      <span role="img" aria-label="jack-o-lantern">
        🎃
      </span>
    );
  }

  pointLeftEmoji() {
    return (
      <span role="img" aria-label="point-left">
        👈
      </span>
    );
  }

  saturday() {
    return (
      <div className="content">
        <article className="message is-dark">
          <div className="message-header">
            <p>Saturday October 31, 2020 {this.halloweenEmoji()}</p>
          </div>
          <div className="message-body">
            <p>
              09:00 - 09:15{diamondSymbol}{" "}
              <strong>Welcome to Day 2</strong>:{" "}
              <a
               href="https://youtu.be/zcQ8jgvIzjw"
               target="_blank"
               rel="noopener noreferrer"
               >
               YouTube LiveStream
              </a>
            </p>
            <p>
              09:15 - 10:00{actionDiamondSymbol}{" "}
              <strong>Design thinking & How to Pitch Workshop:</strong> Jarazet Altamirano & Elisabeth Pfeiffer
            </p>
            <p>10:00 - 12:00{diamondSymbol} Coach Time</p>
            <p>
              12:00 - 12:30{actionDiamondSymbol} <strong>Yoga Session</strong>:{" "}
               <a
                href="https://youtu.be/Qtg9V36lYO4"
                target="_blank"
                rel="noopener noreferrer"
                >
                 YouTube LiveStream
               </a>
            </p>
            <p>13:00 - 14:00{diamondSymbol} Coach Time</p>
            <p>
              <span className="texasRedLink"><strong>before 18:50!</strong>
              {actionDiamondSymbol}</span> Submit your code + presentation to the {" "}
              <a
                style={{ color: "#CC0000" }}
                href="https://github.com/Female-Tech-Leaders-Hackathons/intro-to-robotics"
                target="_blank"
                rel="noopener noreferrer"
              >
                intro-to-robotics GitHub repo
              </a> {this.pointLeftEmoji()}
            </p>
            <p>
              19:00 - 19:50{actionDiamondSymbol}{" "}
              <strong>Pitching and Q & A</strong>:{" "}
              <a
                href="https://youtu.be/z1JgVG6ot1w"
                target="_blank"
                rel="noopener noreferrer"
                >
                YouTube LiveStream
              </a>
            </p>
            <p>
              20:00 - 20:30{actionDiamondSymbol}{" "}
              <strong>Award ceremony and Closing</strong>
            </p>
          </div>
        </article>
      </div>
    );
  }

  render() {
    return (
      <div className="content">
        {this.friday()}
        {this.saturday()}
        <div
          className="content"
          style={{ float: "right", marginBottom: "1.5rem" }}
        >
        </div>
      </div>
    );
  }
}
