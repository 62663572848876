import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import EventsTimeline from "../components/EventsTimeline";
import Contact from "../components/Contact";
import Team from "../components/Team";
import hackingFullImg from "../images/hacking_fullwidth.jpg";

export default class HomePage extends Component {
  initiative() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <div className="content justify-text">
              <p>
                <b>FTL Hackathons</b> is one of the core initiatives within the <a href="https://www.femaletechleaders.org/">
                  Female Tech Leaders</a> (FTL), a non-profit organization based in Munich, Germany,
                dedicated to promoting women in STEM and leadership positions. This initiative
                aims to provide a fun, supportive, hands-on approach to software development
                by offering hackathons to all motivated individuals, regardless of gender.
              </p>
              <p>
                A hackathon, by definition, is:</p>
              <blockquote>
                a usually competitive event in which
                people work in groups on software or hardware projects, with the goal of
                creating a functioning product by the end of the event
                  <cite>
                  <a href="https://www.dictionary.com/browse/hackathon"> dictionary.com</a>
                </cite>
              </blockquote>
              <p>
                As such, FTL Hackathons are themed, multi-day events, which present
                participants with a topic, data, or an idea to work on, in teams of
                (generally) 3-6 people. Previous topics have included Machine Learning,
                working with real-time REST APIs at a host company, as well as the
                COVID-19 global pandemic. Throughout each event, the FTL Hackathons team
                ensures that there are mentors to help answer any questions (either
                technical or non-technical) from teams or individuals. These
                mentors can be FTL members, external volunteers, or employees from a
                host company. At the end of each hackathon, teams present their solution
                to a panel of judges, and are evaluated based on criteria such as
                technical complexity, creativity and usefulness. The winning teams are
                awarded prizes, and all participants go home with new skills,
                experiences, and connections.
              </p>
              <p>
                Each hackathon is unique, and the prerequisites for participation depend
                on the topic presented. Sometimes participants are required to have a
                very technical knowledge base, while other times individuals from all
                areas of software development are encouraged to take part, such as
                graphic designers and product managers. FTL Hackathons usually have a
                capacity of 20-50 participants, and can either be hosted at a company’s
                headquarters or virtually. In the past, some companies have allowed
                participants to stay on-premise for 48+ hours, so participants were
                invited to stay at the office overnight with their team, in true
                hackathon fashion. Depending on the format, FTL Hackathons will include
                free food, workshops (hard and/or soft skills), yoga and always
                technical support.
              </p>
              <p>
                FTL Hackathons is an entirely volunteer-run initiative, and we work
                closely with sponsors who wish to support our cause. These sponsors are
                usually companies or individuals who choose to offer prizes, food,
                beverages, workshops, a talk, or a host location.
              </p>
              {<a className="button" style={{ display: "flex" }} target="_blank" rel="noopener noreferrer"
                href="https://drive.google.com/file/d/1MjQXlloXd54yrHOgCsZ9DyoZChwTMxUz/view?usp=sharing">
                Open FTL Hackathons Slide Deck
                </a>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Header />
        <section className="Home">
          <div className="container welcome-container welcome-container--ftl">
            <img src={hackingFullImg} alt="Working together and hacking" className="teaser-image" />
            <span>
              <h1 className="title is-1 welcome-title">
                Welcome to <br /></h1>
              <h1 className="title is-1 welcome-title ftl-title-text">
                Female Tech Leaders
            </h1>
              <h1 className="title is-1 welcome-title">Hackathons!
            </h1>
            </span>
          </div>
          <div id={"initiative"}>{this.initiative()}</div>
          <div id={"eventsTimeline"}>
            <EventsTimeline />
          </div>
          <div id={"team"}>
            <Team />
          </div>
          <div id={"contact"}>
            <Contact />
          </div>

        </section>
        <Footer />
      </div>
    );
  }
}
