import React, { Component } from "react";
import Person from "../../components/Person";
import lyImg from "../../images/TI_Robotics_Hackathon/headshots/Ly.jpg";

const TI_RED = "CC0000";

export default class TIYoga extends Component {
  lyBio() {
    return (
      <div className="justify-text">
        <p>
          Ly Senger is a yoga instructor/DJ located in Munich, Germany, from Los
          Angeles, California. Originally as a CPA with a background in
          Finance/Accounting working at a big 4 Accounting firm for over 5
          years, in 2014, Ly received her yoga teaching certification under
          Hatha and Ashtanga in Rishikesh, India, where she now teaches and
          specializes in dynamic Yoga and Vinyasa Flow. Also learning to DJ in
          2014, Ly combines live music and yoga by performing at numerous yoga
          Events and Festivals, organizing LYLA Soul Yoga Events and teaching in
          several studios around Munich.
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="content">
        <Person
          boldText="Ly Senger"
          image={lyImg}
          smallText="Yoga Instructor"
          bio={this.lyBio()}
          link={"https://www.djlyla.com/soulyoga"}
          iconColor={TI_RED}
        />
      </div>
    );
  }
}
