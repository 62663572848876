import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faInstagram,
  faTwitterSquare,
  faXing,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeSquare, faLink } from "@fortawesome/free-solid-svg-icons";

export default class Person extends Component {
  render() {
    return (
      <div className="container">
        <article className="media contentMarginBottom">
          <figure className="media-left">
            <div>
              <img
                className="imgPerson"
                src={this.props.image}
                alt={"person"}
              />
            </div>
          </figure>
          <div className="media-content">
            <div className="container">
              <div>{this.props.name}</div>
              <div>
                <strong>{this.props.boldText}</strong>
              </div>
              <div className="content is-small">{this.props.smallText}</div>
              <div className="container">
                {this.props.bio}
                <div>
                  {this.props.email ? (
                    <a href={`mailto:${this.props.email}`}>
                      <FontAwesomeIcon
                        icon={faEnvelopeSquare}
                        size={"2x"}
                        style={{ color: this.props.iconColor }}
                      />{" "}
                    </a>
                  ) : null}
                  {this.props.linkedIn ? (
                    <a
                      href={this.props.linkedIn}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        size={"2x"}
                        style={{ color: this.props.iconColor }}
                      />{" "}
                    </a>
                  ) : null}
                  {this.props.xing ? (
                    <a
                      href={this.props.xing}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faXing}
                        size={"2x"}
                        style={{ color: this.props.iconColor }}
                      />{" "}
                    </a>
                  ) : null}
                  {this.props.instagram ? (
                    <a
                      href={this.props.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        size={"2x"}
                        style={{ color: this.props.iconColor }}
                      />{" "}
                    </a>
                  ) : null}
                  {this.props.twitter ? (
                    <a
                      href={this.props.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faTwitterSquare}
                        size={"2x"}
                        style={{ color: this.props.iconColor }}
                      />{" "}
                    </a>
                  ) : null}
                  {this.props.link ? (
                    <a
                      href={this.props.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faLink}
                        size={"2x"}
                        style={{ color: this.props.iconColor }}
                      />{" "}
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    );
  }
}
