import React, {Component} from "react";
import Person from "../../components/Person";
import elisabethImg from "../../images/TI_Robotics_Hackathon/headshots/Elisabeth.jpg";
import elisabeteImg from "../../images/TI_Robotics_Hackathon/headshots/Elizabete.png";
import jarazetImg from "../../images/TI_Robotics_Hackathon/headshots/Jarazet.jpg";

const TI_RED = "CC0000";

export default class TISpeakers extends Component {
    jarazetBio() {
        return (
            <div className="justify-text">
                <p>Jarazet Altamirano is an innovation champion, mentor, play advocate and certified Agile Leadership
                    practitioner. For the past decade she has been co-creating and implementing frameworks and
                    strategies to
                    help startups, non-profit organizations, and Fortune 500 companies drive innovation, improve their
                    creativity and agility.</p>

                <p>Currently, in her role as the Global SEO Manager at TI, she is working at the intersection of Digital
                    Marketing and Innovation. She is one of the founding members of the TI Innovation Club, where she
                    enjoys
                    mentoring and coaching about Design Thinking, Lean and Agile Methodologies.</p>

                <p>Jarazet holds a bachelor’s degree in Marketing from ITESM and a master's degree in Business
                    Entrepreneurship from the University of Waterloo.</p>


            </div>
        )
    }

    elisabethBio() {
        return (
            <div className="justify-text">
                <p>Elisabeth Pfeiffer is a corporate communicator, innovation ambassador and change agent with proven
                    experience connecting diverse audiences through compelling content and storytelling. She currently
                    manages communications at Texas Instruments across 20 different countries in EMEA in her role as
                    Program Manager, EMEA Corporate Content Strategy, HR Communications and Employee Engagement.</p>

                <p>In 2017, Elisabeth helped to cofound the Innovation Club, an employee-driven incubator that empowers
                    every employee to be an innovator. She has hosted and organized several innovation events, from
                    hackathons to speaker series and workshops.</p>

                <p>Elisabeth holds a bachelor’s degree in Creative Non-Fiction Writing from Scripps College in
                    Claremont, CA and a master's degree in Media and Communication Management with a focus on Digital
                    Entrepreneurship from Macromedia University in Munich, Germany.</p>

            </div>
        );
    }

    elizabeteBio() {
        return (
            <div className="justify-text">
                <p>Elizabete de Freitas is a worldwide Industrial Account Manager and leads a team responsible for all
                technical and commercial activities with Texas Instruments between developing and supporting key
                industrial applications such as wireless connectivity, safety and industrial communications, at EMEA’s
                customers. Previously, she held roles as a Product Marketing Manager as well as a Software Field
                Application Engineer.</p>

                <p>She is a mentor to young women as part of the initiative “BayFiD - Bayerns Frauen in Digitalberufen” by
                the Bavarian Digital Ministry, which aims to support women on their path to digital careers.
                Additionally, she is the Chair of Texas Instruments Germany’s Women’s Initiative.</p>

                <p>Elizabete holds a bachelor’s degree in Electronic Engineering from the Instituto Superior de Tecnologia
                at the University of the Algarve in Portugal and completed a Diploma Thesis at the University of Applied
                    Sciences in Zwickau, Germany.</p>

            </div>
        );
    }

    render() {
        return (
            <div className="content">
                    <Person
                        boldText="Jarazet Altamirano"
                        image={jarazetImg}
                        smallText="Design Thinking & How to Pitch session speaker"
                        bio={this.jarazetBio()}
                        linkedIn={"https://www.linkedin.com/in/jarazet/"}
                        iconColor={TI_RED}
                    />
                    <Person
                        boldText="Elisabeth Pfeiffer"
                        image={elisabethImg}
                        smallText="Design Thinking & How to Pitch session speaker"
                        bio={this.elisabethBio()}
                        linkedIn={"https://www.linkedin.com/in/elisabethpfeiffer/"}
                        iconColor={TI_RED}
                    />
                    <Person
                        boldText="Elizabete de Freitas"
                        image={elisabeteImg}
                        smallText="Speaker and Jury Member"
                        bio={this.elizabeteBio()}
                        linkedIn={"https://www.linkedin.com/in/elizabete-de-freitas-b3b758/"}
                        iconColor={TI_RED}
                    />
            </div>
        );
    }
}
