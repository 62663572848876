import React from "react";

const JudgingCriteria = () => {
    const criteria = [
      {
        category: "Technical",
        desc:
          "Did you implement technologies covered in the workshops?\n" +
          "Wireframe/prototyping or statistical analysis?",
        points: "10",
      },
      {
        category: "Presentation",
        desc: "Is your approach to solving the problem clearly defined?",
        points: "20",
      },
      {
        category: "Creativity",
        desc:
          "Did you extend the tools presented in the workshops?\n" +
          "Were your implementations outside the box?",
        points: "20",
      },
    ];

    const header = ["Category", "Description", "Points"]

    const footer = ["", "", "50"]

    const renderHeader = (
        <tr key="header" className="is-selected" style={{"backgroundColor":"#47C3DC"}}>
            {header.map((headerItem, i) => {
                return (
                    <th key={i}>{headerItem}</th>
                )
            })}
        </tr>
    )
    const renderFooter = (
        <tr key="footer">
            {footer.map((footerItem, i) => {
                return (
                    <th key={i}>{footerItem}</th>
                )
            })}
        </tr>
    )

   function renderTableData() {
        return (criteria.map(criterion => {
            const { category, desc, points } = criterion //destructuring
            return (
                <tr key={category}>
                    <td> <strong>{category}</strong></td>
                    <td>{desc}</td>
                    <td>{points}</td>
                </tr>
            )
        }))
    }

    return (
        <div className="container">
            <div className="content">
                <table id="criteria" className="table is-striped customBoxShadow" >
                    <thead>
                    {renderHeader}
                    </thead>
                    <tfoot>
                    {renderFooter}
                    </tfoot>
                    <tbody>
                    {renderTableData()}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default JudgingCriteria;