import React, { Component } from "react";
import Countdown from "react-countdown";
import liveYoutube from "../../images/TI_Robotics_Hackathon/live_youtube.png";
import ti_RSLK_TEAM from "../../images/TI_Robotics_Hackathon/ti_RSLK_TEAM.jpg";
import TIHeader from "./TIHeader";
import TIAgenda from "./TIAgenda";
import TISpeakers from "./TISpeakers";
import TIYoga from "./TIYoga";
import TIJury from "./TIJury";
import TIPrizes from "./TIPrizes";
import TIFooter from "./TIFooter";
import TIMediaPartner from "./TIMediaPartner";
import TIJudgingCriteria from "./TIJudgingCriteria";

export class SectionTitle extends Component {
  render() {
    return <div className="title is-3 sectionH1">{this.props.section}</div>;
  }
}

const renderer = ({ formatted }) => {
  const dayString = formatted.days !== 1 ? "days" : "day";
  return (
    <div className="columns title is-2">
      <div className="column">
        <span className="countdown-boxes days">{formatted.days === "NaN" ? "00" : formatted.days}</span> {dayString}{" "}
      </div>
      <div className="column">
        <span className="countdown-boxes hours">{formatted.hours === "NaN" ? "00" : formatted.hours}</span> hours{" "}
      </div>
      <div className="column">
        <span className="countdown-boxes mins">{formatted.minutes === "NaN" ? "00" : formatted.minutes}</span> mins{" "}
      </div>
      <div className="column">
        <span className="countdown-boxes secs">{formatted.seconds === "NaN" ? "00" : formatted.seconds}</span> secs{" "}
      </div>
    </div>
  );
};

export default class TexasInstrumentsRobotics extends Component {
  title() {
    return (
      <div className="container countdown-boxes welcome-container--ftl">
        <img
          src={ti_RSLK_TEAM}
          alt="YouTube live"
          className="teaser-image"
        />
        <span>
          <h1 className="title is-1">From Idea to Market:</h1>
          <h1 className="title is-1">
            Intro to Robotics Hackathon
          </h1>
        </span>
      </div>
    );
  }
  resources() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <div className="content justify-text">
              <p>
                Here is a list of additional resources on RSLK MAX to help you
                through the Hackathon
              </p>
              <p>
                <li>
                  <a
                    className="texasRedLink"
                    href="https://www.youtube.com/watch?v=S8O_easZ9LI"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Meet the TI-RSLK MAX
                  </a>
                </li>
                <li>
                  <a
                    className="texasRedLink"
                    href="https://training.ti.com/ti-robotics-system-learning-kit-max"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TI Robotics System Learning Kit MAX (TI-RSLK MAX)
                  </a>
                </li>
                <li>
                  <a
                    className="texasRedLink"
                    href="https://www.ti.com/tool/TIRSLK-EVM?keyMatch=RSLK%20MAX&tisearch=Search-EN-everything&usecase=part-number"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TI-RSLK MAX low cost robotics system learning kit
                  </a>
                </li>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <section className="Home">
        <div className="container welcome-container">
          <TIHeader />
          {this.title()}
          <div className="padding-top">
            <Countdown date={new Date('2020-10-30 13:00:00 GMT+01:00')} renderer={renderer} />
            <div className="is-centred">
              <img className="live-icon" src={liveYoutube} alt="YouTube live" />
              <h3 className="title is-3">
                Join us{" "}
                <a
                  className="texasRedLink underline"
                  href="https://www.youtube.com/playlist?list=PLxRIXQdxuPgRjEddI_BirM9D7vqGaQK7W"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LIVE on YouTube
                </a>
              </h3>
            </div>
          </div>
          <div id={"agenda"}>
            <SectionTitle section={"Agenda"} />
            <TIAgenda />
          </div>
          <div id={"speakers"}>
            <SectionTitle section={"Speakers"} />
            <TISpeakers />
          </div>
          <div id={"jury"}>
            <SectionTitle section={"Jury"} />
            <TIJury />
          </div>
          <div id={"yoga"}>
            <SectionTitle section={"Yoga"} />
            <TIYoga />
          </div>
          <div id={"judging-criteria"}>
            <SectionTitle section={"Judging Criteria"} />
            <TIJudgingCriteria />
          </div>
          <div id={"prizes"}>
            <SectionTitle section={"Prizes"} />
            <TIPrizes />
          </div>
          <div id={"resources"}>
            <SectionTitle section={"Resources"} />
            {this.resources()}
          </div>
          <div id={"media_partner"}>
            <SectionTitle section={"Media Partner"} />
            <TIMediaPartner />
          </div>
        </div>
        <TIFooter />
      </section>
    );
  }
}
