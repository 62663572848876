import React from 'react'
import mapboxgl from 'mapbox-gl';
import './Map.scss';

mapboxgl.accessToken = 'pk.eyJ1IjoiZWhhcmFzb24iLCJhIjoiY2lnb3g3b25nMDBlYnQ3a25yczdwZWRiMSJ9.8CdlL7pTQr9zoI4SXl0Gzg';

const HQ_LAT = 48.137973;
const HQ_LNG = 11.571793;
const HQ_ADDRESS = 'Kaufingerstraße 15, 80331 München';
const MARKER_COLOUR_DARK_PINK = '#ff589a';

export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: HQ_LAT,
            lng: HQ_LNG,
            zoom: 16
        };
    }

    componentDidMount() {
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom
        });

        map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));

        var popup = new mapboxgl.Popup({ offset: 25 }).setHTML(`<strong>FTL Headquarters: </strong><p>${HQ_ADDRESS}</p>`)

        new mapboxgl.Marker({ color: MARKER_COLOUR_DARK_PINK })
            .setLngLat([HQ_LNG, HQ_LAT])
            .setPopup(popup)
            .addTo(map);

        map.on('move', () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2)
            });
        });
    }

    render() {
        return (
            <div ref={el => this.mapContainer = el} className='mapContainer' />
        )
    }
}
