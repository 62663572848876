import React from "react";

const ProblemStatement = () => {
    return (
        <div>
            <p>
                The World Health Organization lists vaccine hesitancy as one of the top 10 threats to global health,
                defining it as “the reluctance or refusal to vaccinate despite the availability of vaccines”. Vaccines
                are a powerful public health tool that were essential in the eradication of smallpox and have
                significantly reduced the prevalence of many other life-threatening infections. With the recent
                emergence of COVID-19 and the development of vaccines to combat it, the importance of vaccination has
                gained a new urgency. People who are vaccine hesitant are not typically ‘anti-vax’, they simply have
                doubts about the safety, effectiveness, or importance of vaccines. The reason for these doubts are
                complex with many causes. Online misinformation has been identified as one of the major causes.
            </p>
            <p style={{marginTop: "1em"}}>
                How can we clearly communicate the credible scientific and medical information about vaccines to the
                general public in a way that addresses their doubts and increases their confidence in vaccines?

            </p>
        </div>
    )
}
export default ProblemStatement;