import React, { Component } from "react";
import Person from "../../components/Person";
import leonieImg from "../../images/Start_Proud/Leonie.jpg";
import kristinaImg from "../../images/FTL_Team/kristina.jpg";
import aidanImg from "../../images/Start_Proud/Aidan.JPG";
import andyImg from "../../images/Start_Proud/Andy.jpg";

const SP_BLUE = "#01b0e5";

export default class Jury extends Component {
  leonieBio() {
    return (
      <div className="justify-text">
        <p>
          Leonie is a Data Analyst and Data Science enthusiast for over 4 years.
          Through her self-starter mentality, her curiosity and passion for
          complex topics, she can extend her skills and knowledge in the data
          and tech field every day. As Chapter Lead of Women in Data Munich and
          as mentor, she pursues the mission of increasing diversity in the tech
          industry and wants to encourage women to make themselves more visible
          in male-dominated professions to close the gender gap.
        </p>

        <p>
          Prior to becoming a Data Analyst, Leonie completed a master’s degree
          in Consumer Affairs, where she gained in-depth knowledge of consumer
          behavior, market research, and statistics. Her favorite activity:
          diving into the minefield of data to discover hidden pattern and
          generate new business insights.
        </p>
      </div>
    );
  }

  aidanBio() {
    return (
      <div className="justify-text">
        <p>
          After graduating from Ryerson with a degree in Industrial Engineering,
          Aidan worked in technology consulting for two years as a systems
          analyst and product owner. During the pandemic he has switched into
          the healthcare industry and is working on the COVID vaccination
          campaign of a local Toronto hospital.
        </p>
        <p>
          Aidan has been volunteering with Start Proud for over 5 years but this
          is his first time working on a hackathon.
        </p>
      </div>
    );
  }

  andyBio() {
    return (
      <div className="justify-text">
        <p>
          Andy brings over a decade of experiences in health tech, academic
          research, entrepreneurship and software development. He is especially
          interested in the intersection between social justice and technology,
          and wholly supports and participates in endeavours that can better the
          lives of other people.
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="content">
        <Person
          boldText="Aidan Messom"
          image={aidanImg}
          smallText="Student Outreach Manager at Start Proud - Fier départ"
          bio={this.aidanBio()}
          linkedIn={"https://www.linkedin.com/in/aidan-messom/"}
          iconColor={SP_BLUE}
        />
        <Person
          boldText="Andy Tran"
          image={andyImg}
          smallText="Senior Front End Developer at CalendarHero"
          bio={this.andyBio()}
          linkedIn={"https://www.linkedin.com/in/andy-tran-toronto"}
          iconColor={SP_BLUE}
        />
        <Person
          boldText="Kristina Skoryk"
          image={kristinaImg}
          smallText="Test Manager at Carl Zeiss Meditec AG, FTL Hackathons Team"
          linkedIn={"https://www.linkedin.com/in/kristina-skoryk-0bb147123/"}
          iconColor={SP_BLUE}
        />
        <Person
          boldText="Leonie Spiller"
          image={leonieImg}
          smallText="Chapter Lead Women in Data Munich & Data Analyst"
          bio={this.leonieBio()}
          linkedIn={
            "https://www.linkedin.com/in/leonie-spiller-814985174/?originalSubdomain=de"
          }
          iconColor={SP_BLUE}
        />
      </div>
    );
  }
}
